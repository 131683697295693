<script>
import ToggleableListItem from '@/components/general/ToggleableListItem'
import ConfirmationModal from '@/components/general/ConfirmationModal.vue'
import ModalIntegrations from '@/components/general/ModalIntegrations.vue'
import Loading from '@/components/general/Loading.vue'
export default {
  components: { ToggleableListItem, ModalIntegrations, ConfirmationModal, Loading },
  name: 'Advertising',
  data () {
    return {
      linkedin: '',
      isReady: false,
      showModal: false,
      showConfirmModal: false,
      showRemoveModal: false,
      getIntegrationsCompany: {},
      integrationSelected: []
    }
  },
  created () {
    this.integrationSelected = []
    this.$store.dispatch('attemptSetShowSpinner', true)
    this.$store.dispatch('attemptGetIntegrations').then((data) => {
      this.isReady = true
      this.getIntegrationsCompany = data
      if (data.static.linkedinId !== null) this.integrationSelected = [{ name: data.static.linkedinId, _id: data.static.linkedinId, token: data.static.linkedinId }]
      this.$store.dispatch('attemptSetShowSpinner', false)
    })
  },
  methods: {
    verifyStatus () {
      return this.getIntegrationsCompany.status === 'regular'
    },
    putLinkedin (val) {
      this.$store.dispatch('attemptPutCompanyLinkedin', typeof val === 'object' && val !== null ? val.firstInput : val)
        .then(() => {
          this.getIntegration()
        })
    },
    getIntegration () {
      this.integrationSelected = []
      this.$store.dispatch('attemptGetIntegrations').then((data) => {
        this.getIntegrationsCompany = data
        if (data.static.linkedinId !== null) this.integrationSelected = [{ name: data.static.linkedinId, _id: data.static.linkedinId, token: data.static.linkedinId }]
        this.showModal = false
        this.showRemoveModal = false
      })
    },
    handleShowModal () {
      this.showModal = !this.showModal
    },
    showConfirmationModal () {
      this.showConfirmModal = !this.showConfirmModal
    },
    showConfirmRemoveModal () {
      this.showRemoveModal = !this.showRemoveModal
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady"/>
    <div class="advertising--container" v-else>
      <p class="advertising--title">{{ $t('admin.center.advertising:description') }}</p>
      <toggleable-list-item @showModalComponent="showConfirmationModal()"
        :item="{ title: $t('admin.center.advertising:ads.plooral.app'), description: $t('admin.center.advertising:ads.plooral.app.description'), image: '/assets/images/Ads/Plooral.png', inputValue: verifyStatus, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="showConfirmationModal()"
        :item="{ title: $t('admin.center.advertising:ads.share.plooral.social'), description: $t('admin.center.advertising:ads.share.plooral.social.description'), image: '/assets/images/Ads/Plooral-yellow.png', inputValue: verifyStatus, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="showConfirmationModal()"
        :item="{ title: $t('admin.center.advertising:ads.google.jobs'), description: $t('admin.center.advertising:ads.google.jobs.description'), image: '/assets/images/Ads/Google.png',inputValue: verifyStatus, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="showConfirmationModal()"
        :item="{ title: $t('admin.center.advertising:ads.indeed'), description: $t('admin.center.advertising:ads.indeed.description'), image: '/assets/images/Ads/Indeed.png',inputValue: verifyStatus, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="verifyStatus ? handleShowModal(): showConfirmationModal()"
        :item="{ title: $t('admin.center.advertising:ads.linkedin'), description: $t('admin.center.advertising:ads.linkedin.description'), image: '/assets/images/Ads/Linkedin.png', inputValue: getIntegrationsCompany.static.linkedinId, isDisabled: true }">
      </toggleable-list-item>
      <confirmation-modal  v-if="verifyStatus"
        :show="showConfirmModal"
        :title="$t('admin.center.advertising:modal.title')"
        :description="$t('admin.center.advertising:modal.description')"
        :confirmText="$t('global:ok')"
        :disableCancelBtn="true"
        :btnConfirmText="false"
        @cancel="showConfirmationModal()"
        @confirm="showConfirmationModal()"
      /> <confirmation-modal v-else
        :show="showConfirmModal"
        :title="$t('integration.warning.test.title')"
        :description="$t('integration.warning.test')"
        :confirmText="$t('global:ok')"
        :disableCancelBtn="true"
        :btnConfirmText="false"
        @cancel="showConfirmationModal()"
        @confirm="showConfirmationModal()"
      />
      <confirmation-modal
        :show="showRemoveModal"
        :title="$t('integration.linkedin.delete.modal.title')"
        :description="$t('integration.linkedin.delete.modal.description') + ` 'LinkedIn Limited Listing'?`"
        :confirmText="$t('global:ok')"
        :btnConfirmText="false"
        @confirm="putLinkedin(null)"
      />
      <ModalIntegrations :show="showModal" @close="handleShowModal()" @add="val=> putLinkedin(val)" @remove="showConfirmRemoveModal()"
        :item="{ title: 'LinkedIn Limited Listing', description: $t('integration.linkedin.details'), tips: $t('integration.linkedin.info').split('$'), firstLabel: $t('integration.linkedin.first.label'), dataToken: integrationSelected, image: '/assets/images/Ads/Linkedin.png' }">
      </ModalIntegrations>
    </div>
  </div>
</template>
<style lang="scss">
.advertising--container {
  .advertising--title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A0A0A0;
  }
}
</style>
